import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from 'react-toastify';
import axios from 'axios';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={"/images/menu-dots.svg"} alt="menu" />
    </a>
));
export default function MenuDropdown({ clickfunction, reloadfunction, id }) {
    const deleteHandle = (id) => {
        const url = `${process.env.REACT_APP_API_URL}/video-finder?ids=${JSON.stringify([id])}`;
        axios.delete(url).then(response => {
     
            reloadfunction();
            toast.error('Deleted successfully')
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
}
return (
    <Dropdown>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu size="sm" title="">
            <Dropdown.Item className="d-flex align-items-center justify-content-end" style={{ width: '50px', float: 'right' }}>
                <img src={"/images/close.svg"} alt="close" />
            </Dropdown.Item>
            <Dropdown.Item className="d-flex align-items-center gap-2" onClick={() => clickfunction(id)}>
                <img src={"/images/info.svg"} alt="info" />
                <p className="mb-0">More info</p>
            </Dropdown.Item>
            <Dropdown.Item className="d-flex align-items-center gap-2" onClick={() => deleteHandle(id)}>
                <img src={"/images/delete.svg"} alt="trash" />
                <p className="mb-0"> Delete</p>
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);
}