import React, { useState } from 'react';
import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';
import { useParams } from 'react-router-dom';
import { hasKeys } from '../shared/utils';
import VideoSearch from '../video-finder/video-search';
import SavedListTable from '../video-finder/saved-video-list-table';
const PageLayout = (props) => {

    const { location } = props;
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth <= 768 ? false : true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768 ? true : false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const params = useParams();
    let divStyle = {};
    let className = '';
    if (location.pathname === '/home') {
        divStyle = {
            minHeight: 'calc(100vh - 64px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        className = 'home-bg';
    }
    else {
        divStyle = {
        // padding: '4px 4px 5px', // Adjust padding as needed
        // maxWidth: '700px', // Set the desired height
        // height:'100%',
        // width:'100%',
        // backgroundSize: '99%', // Adjust the zoom level by changing the percentage
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        };
        className = 'main-div';
    }
    return (
        <>
            <div className="app-container">
                <> <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} toggleSidebar={toggleSidebar} /></>
                <Sidebar params={params} location={location} isOpen={isSidebarOpen} onClose={toggleSidebar} toggleSidebar={toggleSidebar} setIsSidebarOpen={setIsSidebarOpen} setIsMobileView={setIsMobileView} isMobileView={isMobileView} />
                <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-close'}`}>
                    <div className={className} style={divStyle}>
                        {!hasKeys(params.action) && <div style={{ width: '70%' }}><img className='img-fluid' src={"/images/admin-bg.jpeg"}></img></div>}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "search" && <VideoSearch/>}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "videos" && <SavedListTable/>}
                    </div>
                </div>
                <Footer isOpen={isSidebarOpen} />
            </div>
        </>
    );
};

export default PageLayout;
