import Dropdown from 'react-bootstrap/Dropdown';
import UserProfileImage from './profilepicture';
import { useNavigate } from "react-router-dom";

function Profiledropdown() {
    const userpictureurl = localStorage.getItem('userpictureurl');
    const fullname = localStorage.getItem('fullname');
    const userData = {
        imageUrl: userpictureurl ? userpictureurl : "/images/Avatar.png", // Replace with actual image URL
        altText: 'User Profile',
    };
    const navigate = useNavigate();
    const handleLogOut = () => {
        try {
            localStorage.clear()
            navigate('/')
        }
        catch (error) {
            console.error('Navigation error:', error);
        }
    }
    return (
        <>
            <Dropdown data-bs-theme="dark" className='profile-dropdown'>
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="link" className='d-flex align-items-center p-0 gap-4 text-decoration-none'>
                    <div className='d-flex align-items-center gap-2'>
                        <div>
                            <UserProfileImage imageUrl={userData.imageUrl} altText={userData.altText} size={42} />
                        </div>
                        <div className='text-start'>
                            <p className='mb-0 profile-name-text'>{fullname}</p>
                        </div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleLogOut()}>
                        Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default Profiledropdown;