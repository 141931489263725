import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';

function Breadcrumbs() {
    const location = useLocation();
    const pathSetting = () => {
        if (location.pathname === '/home/search'){
            return {parent: "Video Finder", child: "Search"}
        }
        if (location.pathname === '/home/videos'){
            return {parent: "Video Finder", child: "Videos"}
        }
    }
    let pathObj = pathSetting()
    return (
        <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item >{pathObj.parent}</Breadcrumb.Item>
            <Breadcrumb.Item active>{pathObj.child}</Breadcrumb.Item>
        </Breadcrumb>
    );
}

export default Breadcrumbs;