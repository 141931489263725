import React from 'react';

const Footer = ({ isOpen }) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={`footer py-2 ${isOpen ? '' : 'expanded'}`}>
            <div className="container text-center">
                <p className='mb-0'>&copy; {currentYear} Copyrights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
