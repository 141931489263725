import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import SearchListTable from './search-list-table';
import Breadcrumbs from '../svhs/breadcrumbs';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const VideoSeachComponents = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const [courseName, setCourseName] = useState();
    const [course, setCourse] = useState();
    const [courseArray, setCourseArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [lessonArray, setLessonArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [unitId, setUnitId] = useState();
    const [unitTitle, setUnitTitle] = useState();
    const [query, setQuery] = useState();
    const [maxResult, setMaxResult] = useState('');
    const [lessonContent, setLessonContent] = useState('');
    const [lessonId, setLessonId] = useState('');
    const [lessonName, setLessonName] = useState('');
    let searchId = localStorage.getItem('search_id') ? localStorage.getItem('search_id') : 0
    const navigate = useNavigate();
    const url = `${process.env.REACT_APP_API_URL}/video-finder-search`;
    useEffect(() => {
        let formData_1 = localStorage.getItem('tokenData')
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
            .then(userdata => {
                if (!userdata.data.userid) {
                    localStorage.setItem('login', false)
                    navigate('/');
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, [])
    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        const [id, name] = selectedValue.split('%%');
        setCourseId(id);
        setCourse(e.target.value);
        setCourseName(name);
    };
    const handleDescriptionChange = (e) => {
        setLessonContent(e.target.value)
    };
    const handleUnitChange = (e) => {
        const selectedValue = e.target.value;
        const [id, title] = selectedValue.split('%%');
        setUnitId(id);
        setUnitTitle(title);
    };
    const handleLessonChange = (e) => {
        const selectedValue = e.target.value;
        const [id, title, content] = selectedValue.split('%%');
        setLessonId(id);
        setLessonName(title);
        setLessonContent(htmlConverter(content))
    }
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_unit_data&moodlewsrestformat=json&course_id=' + courseId)
            .then((response) => {
                if (response.data) {
                    const decodedData = JSON.parse(response.data.data);
                    let units = decodedData[0].units;
                    setUnitArray(validateUnits(units));
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [courseId])
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=' + unitId)
            .then((response) => {
                if (response.data) {
                    setLessonArray(response.data.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [unitId])
    const handleReset = () => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
                setQuery('');
                setCourseId(0);
                setCourseName('');
                setUnitId('');
                setUnitTitle('');
                setMaxResult('');
                setUnitArray([]);
                setCourse('');
                setLessonId('');
                setLessonContent('');
                setLessonArray([]);
                setLessonName('');
            })
    };
    const handleScrolDown = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }
    const handleClick = () => {
        if (courseId && unitId && query && maxResult && lessonContent && lessonId) {
            if (maxResult > 50) {
                toast.error('max result should not be morethan 50')
            } else {
                setShowSpinner(true);
                const params = {
                    query: query,
                    course_id: courseId,
                    course_name: courseName,
                    unit_id: unitId,
                    unit_title: unitTitle,
                    lesson_id: lessonId,
                    lesson_name: lessonName,
                    lesson_content: lessonContent,
                    max_result: maxResult,
                };

                axios.post(url, params, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {
                    localStorage.setItem('search_id',response.data.data)
                    setShowSpinner(false);
                    handleReset();
                    handleScrolDown();
                })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        } else {
            toast.error('Please complete all fields - they are mandatory.')
        }
    }
    useEffect(()=>{
        getSearchedList()
    },[searchId])
   const getSearchedList = ()=>{
        axios.put(`${url}?search_id=${searchId}`,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
       .then(response => {
                setTableData(response.data.data);
        })
        .catch(error =>{
            console.log("error",error)
        })
    }
    const checkMaxResult = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMaxResult(value)
        }
    }
    const validateUnits = (data) =>{
        const temp = data.slice()
        const val = temp.filter((unit)=> (unit.name.trim()!== "") && unit.name != '.');
        return val
    }
    const htmlConverter = (data) =>{
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = data;
        var plainText = tempDiv.textContent || tempDiv.innerText;
        const parser = new DOMParser();
        const doc = parser.parseFromString(plainText, 'text/html');
        const textContent = doc.body.textContent || '';
        return textContent
    }
    return (
        <section className="video-search-section">
            <Container fluid className='px-4'>
                <Row>
                    <Col xs="12">
                        <div className="search-form-container flex-1 py-3">
                            <Breadcrumbs />
                            <p className="section-title mb-2">Search</p>
                            <div className="form-block mt-3">
                                <div className="d-flex gap-3 flex-wrap">
                                    <Form  style={{ maxWidth: "337px", width: "100%" }}>
                                        <Col>
                                            <Row>
                                                <Form.Group  controlId="course">
                                                    {courseArray && <Form.Select
                                                        aria-label="Default select example"
                                                        value={course}
                                                        onChange={handleSelectChange}>
                                                        <option>Select course</option>
                                                        {courseArray.map(course => (
                                                            <option key={course.Course_ID} value={`${course.Course_ID}%%${course.Course_Name}`}>
                                                                {course.Course_Name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>}
                                                </Form.Group>
                                                <Form.Group controlId="unit" className='d-flex align-items-center py-2'>
                                                    {unitArray && <Form.Select
                                                        aria-label="Default select example"
                                                        onChange={handleUnitChange}
                                                    >
                                                        <option>Select unit</option>
                                                        {unitArray.map(unit => (
                                                            <option key={unit.id} value={`${unit.id}%%${unit.name}`}>
                                                                {unit.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>}
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Form>
                                    <Form  style={{ maxWidth: "337px", width: "100%" }}>
                                        <Form.Group controlId="unit" className='d-flex align-items-center pb-2'>
                                            {courseArray && <Form.Select
                                                aria-label="Default select example"
                                                onChange={handleLessonChange}
                                            >
                                                <option>Select lesson</option>
                                                {lessonArray && lessonArray.map(lesson => (
                                                    <option key={lesson.Id} value={`${lesson.Id}%%${lesson.Lession_Name}%%${lesson.Lession_Content}`}>
                                                        {lesson.Lession_Name}
                                                    </option>
                                                ))}
                                            </Form.Select>}
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="searchQueryString">
                                            <Form.Control type="text" value={query} placeholder="Search Query String" onChange={(e) => { setQuery(e.target.value) }} autoComplete="off" />
                                        </Form.Group>
                                    </Form>
                                    <Form  style={{ maxWidth: "340px", width: "100%" }}>
                                        <Form.Group className="mb-4" controlId="courseModuleDescription">
                                            <Form.Control as="textarea" style={{ height: '120px', width: '450px' }} value={lessonContent} onChange={handleDescriptionChange} />
                                        </Form.Group>
                                    </Form>
                                    <Form className="mb-4 d-flex justify-content-end gap-2" style={{ maxWidth: "200px", width: "100%" }}>
                                        <Col className='d-flex flex-column'>
                                            <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                                <Form.Control type="text" value={maxResult} onChange={(e) => { checkMaxResult(e.target.value) }} autoComplete="off" placeholder='Max Results' />
                                            </Form.Group>
                                            <div className='d-flex gap-3 mt-auto'>
                                                <Col >
                                                    <Button variant="outline-dark" className="w-100" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col >
                                                    <Button onClick={() => { handleClick() }} variant="primary" type="button" className="w-100">
                                                        Search
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Form>
                                </div>
                                <Row>
                                    <Col xs='12'>
                                        <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-1'
                                            />
                                            Search in progress...
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            {tableData && tableData.length !== 0 && <SearchListTable data={tableData} recentSearch={getSearchedList} />}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default VideoSeachComponents;
