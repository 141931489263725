import React from 'react';
import { Image } from 'react-bootstrap';

const UserProfileImage = ({ imageUrl, altText, size }) => {
    const imageSize = size || 50; // Default size: 50px

    const imageStyle = {
        borderRadius: '50%',
        width: imageSize,
        height: imageSize,
        objectFit: 'cover', // Ensure the image covers the entire container
    };

    return <Image src={imageUrl} alt={altText} style={imageStyle} />;
};

export default UserProfileImage;