import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Profiledropdown from './profiledropdown';

function Header({ isSidebarOpen, setIsSidebarOpen, toggleSidebar }) {
    return (
        <>
            <Navbar className='header-navbar' bg="light" data-bs-theme="light">
                <Container fluid>
                    <Nav className="me-auto w-100">
                        <div>
                            <img src={"/images/SVHS-logo.svg"} className='img-fluid' alt="logo" />
                        </div>
                        <div className='ms-auto'>
                            <Profiledropdown></Profiledropdown>
                        </div>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
