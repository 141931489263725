import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const handleLogin = () => {
        setShowSpinner(true)
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('service', 'moodle_mobile_app');
        axios.post('https://'+process.env.REACT_APP_HOST+'/login/token.php?lang=en_us', formData)
            .then(response => {
                if (response.data.token) {
                    setShowSpinner(false)
                    const formData_1 = new URLSearchParams();
                    formData_1.append('moodlewssettingfilter', 'true');
                    formData_1.append('moodlewssettingfileurl', 'true');
                    formData_1.append('moodlewssettinglang', 'en_us');
                    formData_1.append('wsfunction', 'core_webservice_get_site_info');
                    formData_1.append('wstoken', response.data.token);
                    localStorage.setItem('tokenData',formData_1)
                    axios.post('https://'+process.env.REACT_APP_HOST+'/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
                        .then(userdata => {
                            axios.get('https://'+process.env.REACT_APP_HOST+'/webservice/rest/server.php?wstoken='+process.env.REACT_APP_TOKEN+'&wsfunction=local_course_service_ai_data&moodlewsrestformat=json&userid=' + userdata.data.userid)
                                .then(response => {

                                    if (response.data.role_id == 9 || response.data.role_id == 3 || userdata.data.userissiteadmin) {
                                        localStorage.setItem('login', true)
                                        localStorage.setItem('fullname', userdata.data.fullname)
                                        localStorage.setItem('userpictureurl', userdata.data.userpictureurl)
                                                                                navigate('/home');
                                        toast.info('Logged in successfully')
                                    } else {
                                        toast.warning('Please login as admin')
                                    }
                                })
                        })
                }
                else {
                    toast.error('Invalid credentials')
                }
            })
            .catch(error => {
                toast.error('Oops, something went wrong..!')
            });
    };
    return (
        <section className="login-wrapper">
            <Container fluid>
                <Row>
                    <Col xs="12" lg="6" className="d-none d-lg-block login-bg-wrapper">
                        <div className='login-bg-img'></div>
                    </Col>
                    <Col xs="12" lg="6">
                        <div className="login-form-container flex-1">
                            <div>
                                <div className="login-block">
                                    <div className="text-start">
                                        <Image src={"/images/Svhs-logo.png"} alt="logo" />
                                    </div>
                                    <Form className="mt-4">
                                        <p className="title-text">Sign In</p>
                                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="Enter Username" autoComplete='off' value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </Form.Group>
                                        <div>
                                            <Button variant="primary" className="w-100" onClick={() => { handleLogin() }} disabled={!username || !password}>
                                                Sign In
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="bottom-content mb-4 d-flex justify-content-between align-items-center mt-auto">
                                <div className="external-link">
                                    <Button variant="link" className="p-0">
                                        Privacy Policy
                                    </Button>
                                </div>
                                <p className="copyright-text mb-0">Copyright © {currentYear}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='me-1'
                            />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default LoginPage;
