import Pagination from 'react-bootstrap/Pagination';

function Paginations({ current_page, page_size, total_page, total_records,clicked_page }) {
  

    const handlePaginationClick = (pageNumber) => {
        clicked_page(pageNumber);
        // Add logic to fetch data for the clicked page
        console.log(`Clicked page ${pageNumber}`);
    };

    const pagesToShow = [];
    const totalPages = Math.ceil(total_records / page_size);

    for (let i = 1; i <= total_page; i++) {
        pagesToShow.push(
            <Pagination.Item
                key={i}
                active={i === current_page}
                onClick={() => handlePaginationClick(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return (
        <Pagination className='pagination-wrapper gap-2'>
            <Pagination.First
                disabled={current_page === 1}
                onClick={() => handlePaginationClick(1)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-left-double.svg"} alt="first" />
                </span>
            </Pagination.First>
            <Pagination.Prev
                disabled={current_page === 1}
                onClick={() => handlePaginationClick(current_page - 1)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-left.svg"} alt="prev" />
                </span>
            </Pagination.Prev>
            {pagesToShow}
            <Pagination.Next
                className="custom-next-btn"
                disabled={current_page === totalPages}
                onClick={() => handlePaginationClick(current_page + 1)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-right.svg"} alt="next" />
                </span>
            </Pagination.Next>
            <Pagination.Last
                disabled={current_page === totalPages}
                onClick={() => handlePaginationClick(totalPages)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-right-double.svg"} alt="last" />
                </span>
            </Pagination.Last>
        </Pagination>
    );
}

export default Paginations;