import { Container, Row, Col, Form, Button, Modal, Tabs, Tab, Table, Spinner} from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import Paginations from "../svhs/pagination";
import MenuDropdown from "../svhs/dropdownmenu";
import Breadcrumbs from "../svhs/breadcrumbs";
import { useNavigate } from "react-router-dom";

const SavedListTable = () => {
  const [parentCheck, setParentCheck] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);
  const [visibleCharacters, setVisibleCharacters] = useState(2000);
  const [searchQuery, setSearchQuery] = useState();
  const [videoTitle, setVideoTitle] = useState();
  const [courseId, setCourseId] = useState();
  const [unitId, setUnitId] = useState();
  const [lessonId, setLessonId] = useState();
  const [channelName, setChannelName] = useState();
  const [courseArray, setCourseArray] = useState([]);
  const [unitArray, setUnitArray] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [clickPage, setClickPage] = useState(1);
  const [deletedisable, setDeleteDisable] = useState(true);
  const [lessonArray, setLessonArray] = useState([]);
  const navigate = useNavigate();
  const cellStyle = {
    maxWidth: '100px', /* Set your preferred max-width */
    whiteSpace: 'nowrap', /* Prevent text wrapping */
    overflow: 'hidden', /* Hide overflowing content */
    textOverflow: 'ellipsis', /* Show ellipsis for truncated content */
  };
  useEffect(()=>{
    let formData_1 = localStorage.getItem('tokenData')
    axios.post('https://'+process.env.REACT_APP_HOST+'/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
        .then(userdata => {
            if (!userdata.data.userid){
                localStorage.setItem('login', false)
                navigate('/');
            }
        })
        .catch(error => {
            console.log(error)
        });
  },[])
  const getApicall = () => {
    setShowSpinner(true)
    setTableData([]);
    const url = `${process.env.REACT_APP_API_URL}/video-finder?page=` + clickPage;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json' // You can set the Accept header if needed
      }
    }).then(response => {
      setPaginationData(response.data.page_data);
      setTableData(response.data.data);
      setSelectedRow([]);
      setParentCheck(false);
      setShowSpinner(false);
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  useEffect(() => {
    if (searchQuery || courseId || unitId || videoTitle || channelName || lessonId) {
      handleSearch(true);
    }
    else {
      getApicall();
    }
  }, [clickPage])
  useEffect(() => {
    if (parentCheck || selectedRow.length > 0) {
      setDeleteDisable(false)
    } else {
      setDeleteDisable(true)
    }
  }, [parentCheck, selectedRow])
  const handleCheck = (checked, id) => {
    if (checked) {
      const temp = [...selectedRow, id];
      setSelectedRow(temp);
    } else {
      const temp = selectedRow.filter(val => val !== id);
      setSelectedRow(temp);
    }
  };
  const handleParentCheck = (checked) => {
    if (checked) {
      setParentCheck(true)
    }
    else {
      setParentCheck(false)
      setSelectedRow([])
    }
  }
  const handleDeleteClick = () => {
    if (parentCheck){
      for (var i = 0; i < tableData.length; i++) {
        var currentId = tableData[i].id;
        if (selectedRow.indexOf(currentId) === -1) {
          selectedRow.push(currentId);
        }
      }
    }
    setDeleteModel(true)
  }
  const handleDelete = () => {
    const url = `${process.env.REACT_APP_API_URL}/video-finder?ids=${JSON.stringify(selectedRow)}`;
    axios.delete(url).then(response => {
      setSelectedRow([])
      setDeleteModel(false);
      getApicall();
      toast.error('Deleted successfully')
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  const modalClick = (id) => {
    setIsOpen(true);
    const url = `${process.env.REACT_APP_API_URL}/video-finder-search?id=${id}`;
    axios.get(url, {
      headers: {
        'Accept': 'application/json' // You can set the Accept header if needed
      }
    }).then(response => {
      setApiResponse(response.data.data);
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  const closePopup = () => {
    setIsOpen(false);
  };
  const handleShowMore = () => {
    setVisibleCharacters(prevVisibleCharacters => prevVisibleCharacters + 1000); // Increment by 200 characters
  };
  useEffect(() => {
    axios.post('https://'+process.env.REACT_APP_HOST+'/webservice/rest/server.php?wstoken='+process.env.REACT_APP_TOKEN+'&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
      .then((response) => {
        setCourseArray(response.data.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);
  useEffect(() => {if(courseId){
    axios.post('https://'+process.env.REACT_APP_HOST+'/webservice/rest/server.php?wstoken='+process.env.REACT_APP_TOKEN+'&wsfunction=local_course_service_course_unit_data&moodlewsrestformat=json&course_id=' + courseId)
      .then((response) => {
        if (response.data) {
          const decodedData = JSON.parse(response.data.data);
          let units = decodedData[0].units;
          setUnitArray(validateUnits(units));
        }
      })
      .catch((err) => {
        console.log(err)
      })}else{setUnitArray([]);}
  }, [courseId])
  useEffect(() => {
    axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=' + unitId)
        .then((response) => {
            if (response.data) {
                setLessonArray(response.data.data);
            }
        })
        .catch((err) => {
            console.log(err)
        })
}, [unitId])
  const handleSearch = (status) => {
    let search_dict = {}
    if (searchQuery) {
      search_dict["search_name"] = searchQuery
    }
    if (courseId) {
      search_dict["course_id"] = courseId
    }
    if (unitId) {
      search_dict["unit_id"] = unitId
    }
    if (videoTitle) {
      search_dict["video_title"] = videoTitle
    }
    if (channelName) {
      search_dict["channel_title"] = channelName
    }
    if (lessonId) {
      search_dict["lesson_id"] = lessonId
    }
    let page = 1
    let page_size = 10
    let data = {
      search_dict: search_dict,
      page: status && clickPage ? clickPage : page,
      page_size: page_size
    }
    const url = `${process.env.REACT_APP_API_URL}/video-finder`;
    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json' // You can set the Accept header if needed
      }
    }).then(response => {
      setPaginationData(response.data.page_data);
      setTableData(response.data.data);
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  const handleReset = () => {
    axios.post('https://'+process.env.REACT_APP_HOST+'/webservice/rest/server.php?wstoken='+process.env.REACT_APP_TOKEN+'&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
      .then((response) => {
        setCourseArray(response.data.data);
        setSearchQuery('');
        setCourseId('');
        setUnitId('');
        setVideoTitle('');
        setChannelName('');
        setLessonId('');
      })
  };
  const validateUnits = (data) =>{
    const temp = data.slice()
    const val = temp.filter((unit)=> (unit.name.trim()!== "") && unit.name != '.');
    return val
}
  const title = useMemo(()=>{
    return [
      {
        title: (
          <Form.Group controlId="subscriptionall">
            <Form.Check
              checked={parentCheck}
              type="checkbox"
              id='subscriptionall'
              onChange={(e) => { handleParentCheck(e.target.checked) }
              } />
          </Form.Group>
        ),
      },
      {
        title: "Search Query",
      },
      {
        title: "Video Title",
      },
      {
        title: "Course",
      },
      {
        title: "Unit",
      },
      {
        title: "Lesson",
      },
      {
        title: "Video URL",
      },
      {
        title: "Channel",
      },
      {
        title: " Views",
      },
      {
        title: " Likes",
      },
      {
        title: " Duration",
      },
      {
        title: "Relevancy Score"
      },
      {
        title: "Action",
      }]
  },[parentCheck])
  return (
    <Container fluid className="p-4">
      <Breadcrumbs />
      <p className="section-title mb-5">Videos</p>
      <Form className="form-column">
        <Row >
          <Col className="mb-3" xs="11">
            <Row>
              <Col >
                {courseArray && <Form.Select
                  aria-label="Default select example"
                  value={courseId}
                  onChange={(e) => (setCourseId(e.target.value !=='0' ? e.target.value : null))}>
                  <option value='0'>Select course</option>
                  {courseArray.map(course => (
                    <option key={course.Course_ID} value={course.Course_ID}>
                      {course.Course_Name}
                    </option>
                  ))}
                </Form.Select>}
              </Col>
              <Col>
                {unitArray && <Form.Select
                  aria-label="Default select example"
                  value={unitId}
                  onChange={(e) => setUnitId(e.target.value)}
                >
                  <option>Select unit</option>
                  {unitArray.map(unit => (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  ))}
                </Form.Select>}
              </Col>
              <Col>
                <Form.Group controlId="unit" className='d-flex align-items-center pb-2'>
                  {courseArray && <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setLessonId(e.target.value)}
                  >
                      <option>Select lesson</option>
                      {lessonArray && lessonArray.map(lesson => (
                          <option key={lesson.Id} value={lesson.Id}>
                              {lesson.Lession_Name}
                          </option>
                      ))}
                </Form.Select>}
            </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="search" className='d-flex align-items-center'>
                  <Form.Control type="text" autoComplete='off' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="search query" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="search" className='d-flex align-items-center'>
                  <Form.Control type="text" autoComplete='off' value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)} placeholder="Video title" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="search" className='d-flex align-items-center'>
                  <Form.Control type="text" autoComplete='off' value={channelName} onChange={(e) => setChannelName(e.target.value)} placeholder="Channel name" />
                </Form.Group>
              </Col>
              <Col className="d-flex gap-2">
                <div>
                  <Button variant="outline-secondary" type="button" className="" onClick={() => { getApicall(); handleReset() }} >
                    Clear
                  </Button>
                </div>
                <div>
                  <Button variant="primary" type="button" className="" onClick={() => { handleSearch(false) }} disabled={!courseId & !unitId & !videoTitle & !searchQuery & !channelName}>
                    Search
                  </Button>
                </div></Col>
            </Row></Col>
          {tableData.length ? <>
            <div className='saved-list-table' >
            <Col className="text-start" xs='12' >
            <Button variant="link" type="button" className="px-0 delete-link" onClick={() =>{handleDeleteClick()} }
              disabled={deletedisable}>
                <div className="d-flex align-items center gap-1">
                  <img src={'/images/trash.svg'} alt='delete'/>
                  Delete
                </div>
            </Button></Col>
              <Table responsive >
                <thead>
                  <tr>
                    {title.map((val) => (
                      <th>{val.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((cd) => (
                      <tr key={cd.video_id + 'key'}>
                        <td>
                          <Form.Group controlId="subscription">
                            <Form.Check
                              type="checkbox"
                              id={cd.id}
                              checked={
                                selectedRow.includes(cd.id) || parentCheck ? true : false
                              }
                              onChange={(e) => { handleCheck(e.target.checked, cd.id) }
                              }
                              disabled={parentCheck}
                            />
                          </Form.Group>
                        </td>
                        <td className="text-start" style={cellStyle}><a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={cd.search_name}
                          data-tooltip-place="top"
                        >{cd.search_name}</a></td>
                        <td style={cellStyle}><a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={cd.video_title}
                          data-tooltip-place="top"
                        >{cd.video_title}</a></td>
                        <td className="text-start" style={cellStyle}><a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={cd.course_name}
                          data-tooltip-place="top"
                        >{cd.course_name}</a></td>
                        <td style={cellStyle}><a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={cd.unit_title}
                          data-tooltip-place="top"
                        >{cd.unit_title}</a></td>
                        <td style={cellStyle}><a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={cd.lesson_name}
                          data-tooltip-place="top"
                        >{cd.lesson_name}</a></td>
                        <td style={cellStyle}><a href={cd.video_url} target="_blank">{cd.video_url}</a></td>
                        <td className="text-start" style={cellStyle}><a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={cd.channel_title}
                          data-tooltip-place="top"
                        >{cd.channel_title}</a></td>
                        <td style={cellStyle}>{cd.views}</td>
                        <td style={cellStyle}>{cd.likes}</td>
                        <td style={cellStyle}>{cd.duration}</td>
                        <td style={cellStyle}>{cd.relevancy_score ? cd.relevancy_score : "In progress.."}</td>
                        <td> <MenuDropdown clickfunction={modalClick} reloadfunction={getApicall} id={cd.id} /></td>
                      </tr>
                    ))}
                </tbody>
              </Table></div></> : <>
            </>}
        </Row>
        <Row>
          <Col className="d-flex justify-content-start disabled">
            Displaying {paginationData.page_size} of {paginationData.total_records} Records
          </Col>
          <Col className="d-flex justify-content-end">
            <Paginations
              current_page={paginationData.current_page}
              page_size={paginationData.page_size}
              total_page={paginationData.total_page}
              total_records={paginationData.total_records}
              clicked_page={setClickPage} 
              />
          </Col>
        </Row>
          <Row>
            <Col xs='12'>
                <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className='me-1'
                    />
                </Button>
            </Col>
          </Row>
      </Form>
      {isOpen && (
        <div className="modal">
          <div className="modal-content" style={{ maxHeight: '650px',minHeight: '650px', overflowY: 'auto' }}>
            <span className="close" onClick={closePopup}>&times;</span>
            <center className="mb-4">
              <h2>{apiResponse.course_name}</h2>
            </center>
            <h4 className="mb-4"> Unit : {apiResponse.unit_title}</h4>
            <Modal.Body>
            <Tabs defaultActiveKey="questions" id="nav-tabs">
              <Tab eventKey="questions" title="Questions">
                {apiResponse.questions ? (
                  <div className="content text-start mt-2">
                    <h5 className="mb-3">Questions From Transcript</h5>
                    {apiResponse.questions.map((item, index) => (
                      <div key={index}>
                        <h6>{`${index + 1}) ${item.q}`}</h6>
                        <p className="mb-5"><b style={{fontStyle:'italic'}}>Answer : </b>{item.a}</p>
                      </div>
                    ))}
                  </div>
                ): (<p>In progress..</p>)}
              </Tab>
              <Tab eventKey="aiComment" title="AI Comment">
                <div className="content text-start mt-2">
                  <label><b>Summary : </b></label><p>{apiResponse.summary ? apiResponse.summary : "In progress.."}</p>
                </div>
              </Tab>
              <Tab eventKey="transcript" title="Transcript">
                <div className="content text-start mt-2">
                  <p>
                    <label><b>Transcript : </b></label>
                    {apiResponse.transcript && (
                      <>
                        {visibleCharacters >= apiResponse.transcript.length
                          ? apiResponse.transcript
                          : `${apiResponse.transcript.slice(0, visibleCharacters)}...`}
                        {visibleCharacters < apiResponse.transcript.length && (
                          <span onClick={handleShowMore} style={{ color: 'blue', cursor: 'pointer' }}>
                            Show more
                          </span>
                        )}
                      </>
                    )}
                  </p>
                </div>
              </Tab>
            </Tabs>
        </Modal.Body>
            <div className="modal-footer">
              <button className="footer-close btn btn-primary" onClick={closePopup}>Close</button>
            </div>
          </div>
        </div>
      )}
      {deleteModel && <Modal show={deleteModel} onHide={() => setDeleteModel(false)} className='modal-block' centered size="lg">
        <Modal.Header>
          <h5>Confirmation</h5>
        </Modal.Header>
        <Modal.Body>
          Are you sure do you want to delete?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { handleDelete() }}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => setDeleteModel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>}
    </Container>
  );
};
export default SavedListTable;
